import { useState } from "react";
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverGroup, PopoverPanel } from "@headlessui/react";
import { ArrowPathIcon, Bars3Icon, ChartPieIcon, CursorArrowRaysIcon, FingerPrintIcon, SquaresPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import logo from "../../assets/logo/palm-smart-chain-logo.png";
import { HashLink as Link } from "react-router-hash-link";
 function openTab(){
    window.location.href="https://palmsmartchain.io/arabic/";
 }
 function openLogin(){
    window.location.href="https://wallet.palmsmartchain.io/sign-up?referralCode=palmsmartchain";
 }

const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <header className="bg-black relative z-10">
            <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-2">
                <div className="flex lg:flex-1">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Palm Smart Chain</span>
                        <img alt="" src={logo} className="h-16 w-auto" />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button type="button" onClick={() => setMobileMenuOpen(true)} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white">
                        <span className="sr-only">Menu</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-8">
                    <Link to="./#home" className="menuTextColor">
                        Home
                    </Link>
                    <Link to="./#About" className="menuTextColor">
                        About Us
                    </Link>
                    <Link to="./#Ecosystem" className="menuTextColor">
                    Our Services
                    </Link>
                    <Link to="./#Product" className="menuTextColor">
                        Our Products
                    </Link>
                    <Link to="./#Roadmaps" className="menuTextColor">
                        Roadmap
                    </Link>
                    <Link to="./#Tokenimics" className="menuTextColor">
                    Tokenomics
                    </Link>
                    <Link to="./#Team" className="menuTextColor">
                        Team
                    </Link>
                    <Link to="./#Community" className="menuTextColor">
                        Community
                    </Link>
                    <Link to="https://doc.palmsmartchain.io/" target="_blank" className="menuTextColor">
                        Docs
                    </Link>
                </PopoverGroup>
                <div className="hidden lg:flex lg:justify-end ml-3">
                     <Link onClick={openLogin} className="StartBuilding py-2">
                        Launch Wallet
                     </Link>
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end ml-3">
                     <Link onClick={openTab} className="StartBuilding py-2">
                        Arabic
                     </Link>
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden relative z-10">
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img alt="" src={logo} className="h-16 w-auto" />
                        </Link>
                        <button type="button" onClick={() => setMobileMenuOpen(false)} className="-m-2.5 rounded-md p-2.5 text-gray-700">
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Link to="./#home" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Home
                                </Link>
                                <Link to="./#About" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                About Us
                                </Link>
                                <Link to="./#Ecosystem" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                Our Services
                                </Link>
                                <Link to="./#Product" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Our Products
                                </Link>
                                <Link to="./#Roadmaps" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Roadmap
                                </Link>
                                <Link to="./#Tokenimics" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                Tokenomics
                                </Link>
                                <Link to="./#Team" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Team
                                </Link>
                                <Link to="./#Community" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Community 
                                </Link>
                                <Link to="https://doc.palmsmartchain.io/" target="_blank" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Docs 
                                </Link>
                                <Link onClick={openLogin} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                Launch Wallet
                                </Link>
                                <Link onClick={openTab} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    Arabic
                                </Link>
                                
                            </div>
                             
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
};

export default Header;
