import { Container, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/pscs.png";

const OurServices = () => {
    return (
        <div className="mainOurServices py-20 relative isolate">
            <div className='absolute top-0 z-0'>
                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50' alt='' />
            </div>
            <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                <div
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#7143fa] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>
            <Container maxWidth="xl">
                <div className="text-center mb-20">
                    <div className="text-2xl font-normal text-white mb-5">Welcome To</div>
                    <div className="text-6xl font-bold gradient-text">Palm Smart Chain Services</div>
                </div>
                <Grid container spacing={2}>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>
                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">EVM  (Ethereum Virtual Machine) Blockchain Development</div>
                                    <div className="text-sm text-white">
                                        Palm Smart Chain specializes in developing robust, secure, and scalable EVM-compatible blockchains. We help businesses leverage Ethereum's decentralized infrastructure while providing customization
                                        options to meet specific needs, ensuring optimal performance, low transaction fees, and smooth integration with dApps and existing EVM ecosystems.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Token Creation</div>
                                    <div className="text-sm text-white">
                                        Our team at Palm Smart Chain excels in creating custom tokens, whether for utility, security, or governance. We support a wide range of standards, including ERC20 and BEP20, and ensure that your
                                        tokens are secure, compliant, and tailored to power your blockchain-based applications and ecosystems.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Staking Platform</div>
                                    <div className="text-sm text-white">
                                        Palm Smart Chain offers comprehensive staking platform development services, enabling token holders to earn rewards while securing the network. We provide customizable staking mechanisms that are
                                        secure, user-friendly, and integrated with various smart contracts, helping you boost community engagement and strengthen your blockchain ecosystem.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Crypto Wallets</div>
                                    <div className="text-sm text-white">
                                        Our crypto wallet development services ensure secure, multi-currency storage and seamless transactions. Palm Smart Chain builds both custodial and non-custodial wallets, supporting major
                                        cryptocurrencies and tokens. We prioritize security, user experience, and accessibility, delivering wallets that enhance user trust and facilitate easy management of digital assets.
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">NFT Marketplace/NFT Minting</div>
                                    <div className="text-sm text-white">
                                        Palm Smart Chain creates feature-rich NFT (Non-Fungible Token) marketplaces and minting platforms that enable artists, creators, and businesses to tokenize and trade digital assets. Our solutions are secure, scalable, and
                                        customizable, empowering users to mint, sell, and manage NFTs across various industries, from art to gaming.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Bridge/ICO/Presale</div>
                                    <div className="text-sm text-white">
                                        We offer development services for blockchain bridges, ICOs, and presales, facilitating smooth token transfers across different networks and enabling seamless fundraising. Palm Smart Chain ensures
                                        compliance, security, and transparency, helping projects launch successfully and attract investors while enabling cross-chain functionality.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">DEX / IDO </div>
                                    <div className="text-sm text-white">
                                        Palm Smart Chain builds decentralized exchanges (DEX) and Initial DEX Offering (IDO) platforms, offering users a secure, transparent way to trade and invest in digital assets. Our DEX/IDO solutions
                                        prioritize liquidity, user experience, and security, supporting businesses in launching and scaling decentralized finance (DeFi) projects.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Crypto Wallet Payment</div>
                                    <div className="text-sm text-white">
                                        We provide end-to-end development of crypto wallet payment systems, allowing users to make seamless payments using digital currencies. Palm Smart Chain's solutions integrate with various payment
                                        gateways, enabling businesses to accept cryptocurrencies securely, facilitating faster transactions and enhancing the adoption of digital payments.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                            <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Real Assets Tokenization </div>
                                    <div className="text-sm text-white">
                                        Palm Smart Chain specializes in real asset tokenization, enabling businesses to create digital representations of physical assets like real estate, commodities, or art. Tokenization solutions ensure
                                        transparent ownership, fractionalization, and liquidity, unlocking new investment opportunities while ensuring compliance and security for all stakeholders
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                        <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>

                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Auditor</div>
                                    <div className="text-sm text-white">
                                        Our auditing services help ensure the security and integrity of smart contracts and blockchain infrastructure. Palm Smart Chain conducts comprehensive code reviews and vulnerability assessments,
                                        providing detailed reports and recommendations to safeguard your projects from potential risks and maintain trust within your ecosystem.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                        <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>
                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Blockchain Commercial Registration </div>
                                    <div className="text-sm text-white">
                                    Register your blockchain company with ease and professionalism. Our streamlined registration process ensures compliance, security, and efficiency. Whether you're launching a private blockchain, token platform, or dApp, we provide expert support to get your company up and running quickly. Start your blockchain journey with confidence!
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <div className="card_new_psc h-full" style={{ borderRadius: '20px' }}>
                        <div className='absolute top-0 z-0'>
                                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
                            </div>
                            <div className="inner_card_psc h-full border-2 border-slate-800" style={{ borderRadius: '20px' }}>
                                <div className="flex items-center justify-between mb-5">
                                    <div className="bg-slate-900 p-1 rounded-2xl border-2 border-slate-700">
                                        <img src={logo} className="w-16 animate-pulse" alt="" />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="contentcards text-2xl text-white mb-3">Web3 Games</div>
                                    <div className="text-sm text-white">
                                        We develop immersive Web3 games powered by blockchain technology, offering players true ownership of in-game assets and the ability to trade them securely. Palm Smart Chain's game development services
                                        combine engaging game play with decentralized mechanics, unlocking new revenue streams and enhancing player experiences through NFTs and play-to-earn models.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div aria-hidden="true" className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <div
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#8046fc] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                />
            </div>
        </div>
    );
};

export default OurServices;
