import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import twitter from '../../../assets/social-media/twitter.svg'
import Coinmarketcap from '../../../assets/social-media/coinmarketcap-1.svg'
import Discord from '../../../assets/social-media/discover.svg'
import Linkdin from '../../../assets/social-media/linkdin.svg'
import Telegram from '../../../assets/social-media/telegram.svg'
import YouTube from '../../../assets/social-media/youtube.svg'
import media from '../../../assets/social-media/m.svg'
import facebook from '../../../assets/social-media/facebook.jpeg'
import instagram from '../../../assets/social-media/Instagram.webp'

const JoinUs = () => {
    return (
        <div className='relative py-32'>
            <div className='absolute top-0 z-0'>
                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
            </div>
            <Container maxWidth="lg" className="relative">
                <div className='text-center mb-14'>
                    <div className="text-2xl font-normal text-white mb-5">Join Our</div>
                    <div className="text-6xl font-bold gradient-text">Community</div>
                </div>
                <Grid container spacing={2}>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={facebook} alt='' className='w-12 h-12 m-auto object-contain rounded-full' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Facebook</h4>
                                </div>
                                <div>
                                    <Link to='https://www.facebook.com/palmsmartchain' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={instagram} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Instagram</h4>
                                </div>
                                <div>
                                    <Link to='https://www.instagram.com/palmsmartchain/' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={twitter} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Twitter</h4>
                                </div>
                                <div>
                                    <Link to='https://x.com/palmsmartchain' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={YouTube} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>YouTube</h4>
                                </div>
                                <div>
                                    <Link to='/' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={Telegram} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Telegram</h4>
                                </div>
                                <div>
                                    <Link to='/' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={Linkdin} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Linkedin</h4>
                                </div>
                                <div>
                                    <Link to='https://www.linkedin.com/company/palmsmartchain' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={Discord} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Discord</h4>
                                </div>
                                <div>
                                    <Link to='https://discord.com/channels/1247849623603773533/1248141333667909643' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    {/*<Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={Coinmarketcap} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Coinmarketcap</h4>
                                </div>
                                <div>
                                    <Link to='/' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>*/}
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-slate-800'>
                            <div className='text-center m-auto'>
                                <div className='imgtwitter text-center'>
                                    <img src={media} alt='' className='w-12 h-12 m-auto object-contain' />
                                </div>
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>Medium</h4>
                                </div>
                                <div>
                                    <Link to='https://medium.com/@palmsmartchain' target='_blank' className='text-center text-white'>
                                        Follow us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
        
    )
}

export default JoinUs