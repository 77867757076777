import { Container, Grid } from "@mui/material";
import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

const FAQ = () => {
    return (
        <div className="relative py-32">
            <div className="absolute top-0 z-0">
                <img src={require("../../../assets/bg-line.png")} className="object-contain opacity-50 w-full h-full" alt="" />
            </div>
            <Container maxWidth="lg" className="relative">
                <div className="text-center mb-14">
                    <div className="text-2xl font-normal text-white mb-5">Plam Smart Chain</div>
                    <div className="text-6xl font-bold gradient-text">Frequently Asked Question</div>
                </div>
                <Grid container spacing={2}>
                    <Grid item lg="12">
                        <div className="">
                        <Accordion sx={{background:'#282633', border:'1px solid #3e3e3e', borderRadius:'6px', boxShadow:'none', marginBottom:'5px'}}>
                                <AccordionSummary sx={{color:'#fff', fontSize:'20px'}} expandIcon={<ExpandMoreIcon sx={{color:'#7b4ae5'}} />} aria-controls="panel5-content" id="panel5-header">
                                    What is Palm Smart Chain?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="text-gray-300">The Palm Smart Chain is an EVM-compatible blockchain that allows seamless migration of smart
                                    contracts and decentralized applications (dApps) from other chains like Ethereum and Binance
                                    Smart Chain. This compatibility ensures that developers can leverage PALM’s ecosystem without
                                    needing to rebuild their existing applications.</p> 
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{background:'#282633', border:'1px solid #3e3e3e', borderRadius:'6px', boxShadow:'none', marginBottom:'5px'}}>
                                <AccordionSummary sx={{color:'#fff', fontSize:'20px'}} expandIcon={<ExpandMoreIcon sx={{color:'#7b4ae5'}} />} aria-controls="panel2-content" id="panel2-header">
                                    What services does PALM offer for entrepreneurs?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="text-gray-300 text-lg">PALM offers a range of services for entrepreneurs, including:</p>
                                    <ul className="ml-5 mt-4">
                                        <li className="text-gray-300">1. Assistance with applying for a government-approved Commercial License around the Globe</li>
                                        <li className="text-gray-300">2. Development support on the Palm Smart Chain.</li>
                                        <li className="text-gray-300">3. Help with crowdfunding initiatives.</li>
                                        <li className="text-gray-300">4. Marketing support for blockchain products</li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{background:'#282633', border:'1px solid #3e3e3e', borderRadius:'6px', boxShadow:'none', marginBottom:'5px'}}>
                                <AccordionSummary sx={{color:'#fff', fontSize:'20px'}} expandIcon={<ExpandMoreIcon sx={{color:'#7b4ae5'}} />} aria-controls="panel3-content" id="panel3-header">
                                    Why is trust important in the PALM ecosystem?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="text-gray-300">Trust is the foundational stone of the PALM ecosystem. It ensures that all projects launched on the
                                    Palm Smart Chain are credible and compliant, thus fostering community acceptance and attracting
                                    funding.</p>
                                     
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{background:'#282633', border:'1px solid #3e3e3e', borderRadius:'6px', boxShadow:'none', marginBottom:'5px'}}>
                                <AccordionSummary sx={{color:'#fff', fontSize:'20px'}} expandIcon={<ExpandMoreIcon sx={{color:'#7b4ae5'}} />} aria-controls="panel4-content" id="panel4-header">
                                What is a Commercial License, and why is it important?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="text-gray-300">A Commercial license is a legal requirement in Bahrain for businesses. Obtaining a license through
                                    PALM helps ensure that blockchain projects are compliant with local regulations, enhancing their
                                    credibility and value.</p>
                                     
                                </AccordionDetails>
                            </Accordion>
                            
                            <Accordion sx={{background:'#282633', border:'1px solid #3e3e3e', borderRadius:'6px', boxShadow:'none', marginBottom:'5px'}}>
                                <AccordionSummary sx={{color:'#fff', fontSize:'20px'}} expandIcon={<ExpandMoreIcon sx={{color:'#7b4ae5'}} />} aria-controls="panel6-content" id="panel6-header">
                                    How does PSC ensure long-term sustainability?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="text-gray-300">PSC is designed with mechanisms that prevent inflation, ensure fair distribution, and incentivize
                                    long-term participation, thereby promoting the ecosystem's sustainability and growth.</p> 
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{background:'#282633', border:'1px solid #3e3e3e', borderRadius:'6px', boxShadow:'none', marginBottom:'5px'}}>
                                <AccordionSummary sx={{color:'#fff', fontSize:'20px'}} expandIcon={<ExpandMoreIcon sx={{color:'#7b4ae5'}} />} aria-controls="panel7-content" id="panel7-header">
                                    What is the role of staking in Palm?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="text-gray-300">Staking is a central part of Palm, allowing users to earn rewards by locking their Palm Coins,
                                    contributing to the security and functionality of the blockchain while supporting the overall
                                    ecosystem.
                                    </p> 
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{background:'#282633', border:'1px solid #3e3e3e', borderRadius:'6px', boxShadow:'none', marginBottom:'5px'}}>
                                <AccordionSummary sx={{color:'#fff', fontSize:'20px'}} expandIcon={<ExpandMoreIcon sx={{color:'#7b4ae5'}} />} aria-controls="panel8-content" id="panel8-header">
                                How are Palm Coins distributed?
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p className="text-gray-300">Palm Coins are distributed according to the Palm distribution plan, which includes allocations for
                                    staking rewards, development, partnerships, community incentives, and ecosystem growth.
                                    </p> 
                                </AccordionDetails>
                            </Accordion>
                            
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default FAQ;
