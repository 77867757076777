import { Container, Grid } from '@mui/material'
import React from 'react'

const OurOfficeBranch = () => {
  return (
    <div className='relative py-32'>
        <div className='absolute top-0 z-0 left-0 right-0'>
            <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full' alt='' />
        </div>
        <Container maxWidth="xl" className="relative">
            <div className='text-center mb-14'> 
                <div className="text-6xl font-bold gradient-text">Our Offices</div>
            </div>
            <Grid container spacing={2}>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/usa-flag-logo.png')} className='w-10 rounded-lg object-contain' />
                                <h4 className='text-white text-2xl'>Delaware, USA</h4>
                            </div>
                            <p className='text-white text-sm'> 8 The Green, STE A, Dover, Delaware, 19901, United States KENT C.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/London-England.jpg')} className='w-10 rounded-lg object-contain' />
                                <h4 className='text-white text-2xl'>London, England</h4>
                            </div>
                            <p className='text-white text-sm'> 20 Wenlock Road, London,England, N1 7GU.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/Flag_of_Panama.png')} className='w-10 rounded-lg object-contain' />
                                <h4 className='text-white text-2xl'>Panama City</h4>
                            </div>
                            <p className='text-white text-sm'> World Trade Center, 53rd St East  Marbella, Panama City, Panama.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/singapore-logo.png')} className='w-10 rounded-lg object-contain' />
                                <h4 className='text-white text-2xl'>Singapore</h4>
                            </div>
                            <p className='text-white text-sm'> No 7 Temasek Boulevard#12-07 Suntec Tower One, 038987 Singapore, Singapore.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/usa-flag-logo.png')} className='w-10 rounded-lg object-contain' />
                                <h4 className='text-white text-2xl'>NY, USA</h4>
                            </div>
                            <p className='text-white text-sm'> 24 Maxwell Drive Clifton Park, NY 12065 USA.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/usa-flag-logo.png')} className='w-10 rounded-lg object-contain' />
                            <h4 className='text-white text-2xl'>Las Vegas, USA</h4>
                            </div>
                            <p className='text-white text-sm'>1155 E Twain Ave Ste 108 Las Vegas, NV 89169.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/Flag_of_Hong_Kong.png')} className='w-10 rounded-lg object-contain' />
                            <h4 className='text-white text-2xl'>Hong Kong</h4>
                            </div>
                            <p className='text-white text-sm'>700 Nathan Road Kowloon, Hong Kong.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item lg="3" md="3" sm="6" xs="12">
                    <div className='Join_Card border-2 border-slate-800'>
                        <div className='px-3'>
                            <div className='flex item gap-2'>
                                <img src={require('../../../assets/Saudi-Arabia.jpg')} className='w-10 rounded-lg object-contain' />
                            <h4 className='text-white text-2xl'>Saudi Arabia</h4>
                            </div>
                            <p className='text-white text-sm'>7522, Abaad Ibn Sahl Ansari, postal code 34438, Al Khobar, Kingdom of Saudi Arabia.</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container> 
    </div>
  )
}

export default OurOfficeBranch